<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="SalesCommissionsOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5>
            <app-card
                :fullBlock="true"
                colClasses="xl12 lg12 md12 sm12 xs12"
            >
                <v-row no-gutters class="pb-0 mb-0" dense>
                    <v-col md="2" xs="6" class="px-1 pb-0 mb-0">
                        <span class="font-xs pa-0 ma-0">{{ $t('message.filterByYear') }}</span>
                    </v-col>
                    <v-col md="3" xs="6" class="px-1 pb-0 mb-0">
                        <span class="font-xs pa-0 ma-0">{{ $t('message.filterByQuarters') }}</span>
                    </v-col>
                    <v-col md="4" xs="6" class="px-1 pb-0 mb-0">
                        <span class="font-xs pa-0 ma-0">{{ $t('message.filterByMonths') }}</span>
                    </v-col>
                    <v-col md="3" xs="6" class="pl-8 pb-0 mb-0">
                        <span class="font-xs pa-0 ma-0">{{ $t('message.salesColleague') }}</span>
                    </v-col>
                </v-row>
                <v-row no-gutters class="pt-0 pl-0 pb-0" dense>
                    <v-radio-group v-model="filters.current" row class="my-0 py-0" hide-details dense>
                        <v-col md="2" xs="6" class="px-2 pb-2 pt-2" :class="filters.current == 'year' ? 'blue lighten-5 rounded-md' : ''">
                            <div class="d-flex flex-row align-center">
                                <v-radio
                                    class="mr-0"
                                    value="year"
                                />
                                <v-select
                                    :items="years"
                                    :disabled="filters.current != 'year'"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="filters.year.year"
                                />
                            </div>
                        </v-col>
                        <v-col md="3" xs="6" class="px-2 pb-2 pt-2" :class="filters.current == 'quarter' ? 'blue lighten-5 rounded-md' : ''">
                            <div class="d-flex flex-row align-center">
                                <v-radio
                                    class="mr-0"
                                    value="quarter"
                                />
                                <v-select
                                    :items="years"
                                    :disabled="filters.current != 'quarter'"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="filters.quarter.year"
                                />
                                <v-select
                                    :items="quarters"
                                    :disabled="filters.current != 'quarter'"
                                    :placeholder="$t('message.all')"
                                    class="ml-3"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    multiple
                                    solo
                                    v-model="filters.quarter.quarters"
                                />
                            </div>
                        </v-col>
                        <v-col md="4" xs="6" class="px-2 pb-2 pt-2" :class="filters.current == 'month' ? 'blue lighten-5 rounded-md' : ''">
                            <div class="d-flex flex-row align-center">
                                <v-radio
                                    class="mr-0"
                                    value="month"
                                />
                                <v-select
                                    :items="years"
                                    :disabled="filters.current != 'month'"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="filters.month.year"
                                />
                                <v-select
                                    :items="months"
                                    :disabled="filters.current != 'month'"
                                    :placeholder="$t('message.all')"
                                    class="ml-3"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Month.code"
                                    item-value="Month.id"
                                    v-model="filters.month.monthFrom"
                                    solo
                                />
                                <span class="pl-2">{{ $t('message.to') }}</span>
                                <v-select
                                    :items="months"
                                    :disabled="filters.current != 'month'"
                                    :placeholder="$t('message.all')"
                                    class="ml-3"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Month.code"
                                    item-value="Month.id"
                                    solo
                                    v-model="filters.month.monthTo"
                                />
                            </div>
                        </v-col>
                        <v-col md="3" xs="6" class="pl-8 pt-2 pb-2">
                            <div class="d-flex flex-row align-center">
                                <v-select
                                    :items="allSalesColleagues"
                                    :placeholder="$t('message.all')"
                                    autocomplete="new-password"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Salesperson.name"
                                    item-value="Salesperson.id"
                                    solo
                                    v-model="filters.salesColleague"
                                />
                                <v-btn
                                    :loading="loading.fetch"
                                    class="ml-2"
                                    @click="loadCommissions()"
                                >{{ $t('message.fetch') }}</v-btn>
                            </div>
                        </v-col>
                    </v-radio-group>
                </v-row>
                <v-tabs
                    v-model="salesCommissionsOverviewCurrentTab"
                    color="success"
                    slider-color="appic-green"
                >
                    <v-tab key="intake" @click="changeExportParams('intake')">{{ $t('message.intake') }}</v-tab>
                    <v-tab key="paid" @click="changeExportParams('paid')">{{ $t('message.paidInvoices') }}</v-tab>
                    <v-tab key="paid-out" @click="changeExportParams('paid-out')">{{ $t('message.paidOutCommissions') }}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="salesCommissionsOverviewCurrentTab">
                    <v-tab-item key="intake-list">
                        <v-card flat>
                            <CommissionIntakeList
                                :intake-items="allIntakeItems"
                                :key="intakeListKey"
                                :loading-items="loading.fetch"
                            />
                        </v-card>
                    </v-tab-item>
                    <v-tab-item key="paid-list">
                        <v-card flat>
                            <CommissionPaidList
                                :paid-items="allPaidItems"
                                :key="paidListKey"
                                :loading-items="loading.fetch"
                                @commission-paid="getAllPaidItems()"
                            />
                        </v-card>
                    </v-tab-item>
                    <v-tab-item key="paid-out-list">
                        <v-card flat>
                            <CommissionPaidOutList
                                :paid-out-items="allPaidOutItems"
                                :key="paidOutListKey"
                                :loading-items="loading.fetch"
                            />
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </app-card>
        </v-container>
    </div>
</template>

<script>
const CommissionPaidOutList = () => import("Components/Appic/CommissionPaidOutList");
const ExportTable = () => import("Components/Appic/ExportTable");
const CommissionPaidList = () => import("Components/Appic/CommissionPaidList");
const CommissionIntakeList = () => import("Components/Appic/CommissionIntakeList");
import {mapGetters, mapActions} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
    name: "SalesCommissionsOverview",
    components: {CommissionPaidOutList, CommissionIntakeList, CommissionPaidList, ExportTable},
    title: '',
    data() {
        return {
            intakeListKey: 0,
            loader: false,
            loading: {
                fetch: false,
            },
            paidListKey: 0,
            paidOutListKey: 0,
            quarters: [
                {value: 1, text: 'Q1'},
                {value: 2, text: 'Q2'},
                {value: 3, text: 'Q3'},
                {value: 4, text: 'Q4'}
            ],
            pageKey: Math.floor(Math.random() * 100),
        }
    },
    computed: {
        ...mapFields('commission',{
            allIntakeItems: 'allIntakeItems',
            allPaidItems: 'allPaidItems',
            allPaidOutItems: 'allPaidOutItems',
            filters: 'filters'
        }),
        ...mapFields('runtime',{
            salesCommissionsOverviewCurrentTab: 'salesCommissionsOverviewCurrentTab'
        }),
        ...mapGetters('salescolleague',{
            allSalesColleagues: 'allSalesColleagues'
        }),
        ...mapGetters([
            'months'
        ]),
        years(){
            let years = []
            let start = 2019
            let end = new Date().getFullYear();
            let i
            for(i = end; i >= start; i--){
                years.push({value: i, text: i.toString()})
            }
            return years
        }
    },
    methods: {
        ...mapActions('commission',{
            getAllIntakeItems: 'getAllIntakeItems',
            getAllPaidItems: 'getAllPaidItems',
            getAllPaidOutItems: 'getAllPaidOutItems'
        }),
        ...mapActions('salescolleague', {
            getAllSalesColleagues: 'getAllSalesColleagues',
        }),
        changeExportParams(itemType){},
        loadCommissions(){
            this.loading.fetch = true
            this.getAllIntakeItems()
                .then(() => {
                    this.intakeListKey = Math.floor(Math.random() * 100)
                    this.getAllPaidItems()
                        .then(() => {
                            this.paidListKey = Math.floor(Math.random() * 100)
                            this.getAllPaidOutItems()
                                .then(() => {
                                    this.paidOutListKey = Math.floor(Math.random() * 100)
                                    this.loading.fetch = false
                                })
                                .catch(() => {
                                    this.loading.fetch = false
                                })
                        })
                        .catch(() => {
                            this.loading.fetch = false
                        })
                })
                .catch(() => {
                    this.loading.fetch = false
                })
        }
    },
    created(){
        if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
    }
}
</script>

<styles>

</styles>